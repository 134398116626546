.home {
    min-height: 100vh;
    background: url("../Images/bg.png") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    &-con {
        padding-left: 3rem;
        height: calc(100vh - 12rem);
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: $mobile) {
            padding-left: 1rem;
          }
    }
    &-text {
        font-size: 100px;
        line-height: 7rem;
        @media only screen and (max-width: $mobile) {
            font-size: 45px;
            line-height: 2rem;
          }
        &--white {
            font-weight: 300;
        }
        &--gold {
            font-weight: bold;
            color: $gold;
        }
    }
    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 2rem 3rem;
        @media only screen and (max-width: $mobile) {
            padding: 2rem 1rem;
            justify-content: center;
          }
        &__logo {
            height: 6rem;
            width: auto;
            @media only screen and (max-width: $mobile) {
                height: 5rem;
              }
        }
        &-links {
            &__link {
                margin-right: 2rem;
                font-weight: normal;
                text-decoration: none;
            }
        }
    }
}
