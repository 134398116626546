.screen {
    background-color: $navyDark;
    min-height: 100vh;

    &-nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 3rem;
        background-color: $navyDark;
        box-shadow: 0px 1px 10px #1d1d1d;
        position: sticky;
        z-index: 999;
        top: 0;
        @media only screen and (max-width: $mobile) {
            padding: 1rem;
          }
        &__logo {
            height: 4rem;
            width: auto;
        }

        &-links {
            display: flex;
            flex-direction: row;
            align-items: center;
            &__link {
                margin-right: 2rem;
                font-weight: normal;
                text-decoration: none;
                margin-bottom: 0;
                @media only screen and (max-width: $tablet) {
                    font-size: 28px;
                    margin-bottom: 1rem;
                  }
                &--active {
                    color: $gold;
                    font-weight: 600;
                }
                &--activeW {
                    font-weight: bold;
                }
            }
        }
    }

    &-wrap {
        background-color: $navy;
        width: 75vw;
        margin: 0 auto;
        display: block;
        @media only screen and (max-width: $laptop) {
            width: 90vw;
          }
          @media only screen and (max-width: $tablet) {
            width: auto;
          }
    }

    &-footer {
        background-color: $navy;
        padding: 2rem 6rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: $mobile) {
            flex-direction: column;
            padding: 2rem;
          }
        &__logo {
            height: 4rem;
            width: auto;
        }

        &__title {
            color: $gold;
            @media only screen and (max-width: $mobile) {
                margin-top: 2rem;
              }
        }

        &__font {
            font-size: 12px;
            font-weight: normal;
            text-decoration: none;
            margin-bottom: .5rem;
        }
    }
}

.mr-3 {
    margin-right: 2rem;
}

.mr-2 {
    margin-right: 1rem;
}

.mr-1 {
    margin-right: .5rem;
}