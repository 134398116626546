.packages-desc {
    width: 40rem;
    text-align: center;

    @media only screen and (max-width: $mobile) {
        width: 100%;
    }
}

.packages-extra {
    font-weight: bold;
}


.packageItem {
    width: 50%;

    display: flex;
    align-items: center;
    padding-right: 2rem;
    margin-bottom: 2rem;
    flex-direction: row;

    @media only screen and (max-width: $tablet) {
        flex-direction: column;
    }

    @media only screen and (max-width: $mobile) {
        width: 100%;
        padding: 0;
    }

    &-head {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $navyDark;
        flex-direction: column;
        width: 13rem;
        min-height: 13rem;
        height: 100%;

        @media only screen and (max-width: $tablet) {
            width: 18rem;
            min-height: 18rem;
        }

        &__title {
            margin: 0;
            font-size: 18px;
            line-height: 2rem;
            text-align: center;
        }

        &__price {
            font-size: 35px;
            font-weight: bold;
            line-height: 2rem;
        }

        &__subprice {
            font-size: 25px;
        }
    }

    &-container {
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;

        width: calc(100% - 13rem);
        background-color: $gold;
        min-height: 13rem;
        padding: 1rem;
        display: flex;
        height: 100%;
        justify-content: space-between;
        flex-wrap: wrap;

        @media only screen and (max-width: $tablet) {
            min-width: 18rem;
            height: 100%;
        }

        &-wrap {
            display: flex;
            flex-wrap: wrap;
            height: fit-content;
        }

        &__item {
            width: 50%;
            margin-bottom: 1rem;
            color: $navyDark;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: 14px;
            font-weight: 600;

            @media only screen and (max-width: $laptop) {
                width: 100%;
            }

            svg {
                margin-right: .5rem;
                background-color: white;
                border-radius: 25px;
                min-width: 20px;
                min-height: 20px;
            }
        }
    }
}