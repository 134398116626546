.vsCarousel {
    &-item {
        position: relative;
        z-index: 100;
        &__img {
            height: 35rem;
            max-height: 35rem;
            background-size: cover;
            background-position: center center;
        }
        &-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 80%;
            height: 100%;
            display: flex;
            align-items: center;
            @media only screen and (max-width: $tablet) {
                width: 100%;
              }
            &-text {
                padding-top: 2rem;
                padding-bottom: 2rem;
                padding-left: 6rem;
                padding-right: 1rem;
                background-color: rgba(24, 62, 71, 0.5);
                @media only screen and (max-width: $tablet) {
                    padding-left: 1rem;
                    text-align: center;
                  }
                &__item {
                    font-size: 20px;
                    font-weight: 600;
                }
            }
        }
    }
}