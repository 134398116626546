.title {
    color: $gold;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    font-size: 40px;
    @media only screen and (max-width: $mobile) {
        text-align: center;
      }
    &--thin {
        color: $gold;
        font-family: 'Lato', sans-serif;
        font-weight: 300;
        font-size: 40px;
    }

    &--white {
        color: white;
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size: 40px;
    }
}

.gold-text {
    color: $gold;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
}

li {
    color: white;
}

.VSButton {
    border-radius: 0;
    background-color: $navy;
    font-weight: 600;
    padding-left: 3rem;
    padding-right: 3rem;
    outline: none;
    border: none;

    &:hover {
        background-color: $navyDark;
    }

    &--gold {
        background-color: $gold !important;
        color: white !important;

        &:hover {
            background-color: #dba21c !important;
        }
    }
}

p {
    color: white;
    font-family: "Montserrat", sans-serif;
}

label {
    color: white;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    margin-bottom: 0 !important;
}

a {
    color: white;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
}

.skewed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $navyAlt;
    z-index: 0;
    transform: skewY(16deg);
    transform-origin: top right;
    @media only screen and (max-width: $mobile) {
        height: 50%;
      }
}

.carousel-control-prev {
    z-index: 998;
}

.carousel-control-next {
    z-index: 998;
}

.carousel-indicators {
    z-index: 998;
}

.font-bold {
    font-weight: 600;
}

.z-998 {
    z-index: 998;
    position: relative;
}

.sample-bg {
    background: url("../Images/carousel.png") no-repeat center center; 
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    padding: 2rem 0;
}

.sampleItem {
    background-color: $navyAlt;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 18rem;
    min-height: 30rem;
    padding: 1rem;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    &__image {
        width: 10rem;
        height: 10rem;
        border-radius: 50px;
        margin-bottom: 1rem;
    }
    &__title {
        font-weight: 600;
        font-size: 20px;
        color:  $gold;
        text-align: center;
        margin-bottom: 0;
    }
}

.carousel-item-padding-40-px {
    width: 18rem !important;
    margin-right: 2rem;
    &:last-of-type {
        margin-right: 0;
    }
}

.carousel-container {
    padding-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.offcanvas {
    width: 50% !important;
    background-color: $navy;
    @media only screen and (max-width: $mobile) {
        width: 80% !important;
      }
}

.about-emp {
    height: 25rem;
    width: 25rem;
    background-size: cover;
    background-position: center center;
    position: relative;
    @media only screen and (max-width: $laptop) {
        height: 20rem;
        width: 20rem;
      }
      @media only screen and (max-width: $tablet) {
        height: 40rem;
        width: 100%;
        margin-bottom: 2rem;
      }
      @media only screen and (max-width: $mobile) {
        height: 30rem;
        width: 100%;
        margin-bottom: 2rem;
      }
    &-details {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 1rem;
        background-color: rgba(18, 48, 53, 0.8);
        &__name {
            color: $gold;
            font-weight: 600;
            font-size: 22px;
            margin: 0;
        }
        &__job {
            margin: 0;
        }
    }
}

.bg-dark {
    background-color: $navyAlt !important;
    ul {
        list-style: disc;
    }
}

.team-social {
    text-decoration: none;
    font-size: 14px;
    margin-bottom: 0 !important;
    cursor: pointer;
    svg {
        margin-right: .3rem;
    }
}