.textImage {
    display: flex;
    flex-direction: row;
    @media only screen and (max-width: $mobile) {
        flex-direction: column;
      }
    &-image {
        width: 40%;
        position: relative;
        display: inline-block;
        @media only screen and (max-width: $mobile) {
            width: 100%;
          }
        &__bg {
            height: 100%;
            padding-bottom: 100%;
            background-size: cover;
        }
    }
    &-text {
        width: 60%;
        padding: 3rem 4rem;
        background-color: $navy;
        display: flex;
        justify-content: center;
        flex-direction: column;
        @media only screen and (max-width: $mobile) {
            width: 100%;
            padding: 3rem 2rem;
          }
        &--dark {
            background-color: $navyAlt;
        }
    }
}